import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import { animated, config, useTransition } from '@react-spring/web';
import Icon from '@uikit/components/Icon/Icon';
import clsx from 'clsx';
import { useState } from 'react';
import useIsMobile from '../../../hooks/useIsMobile/useIsMobile';
import { LocationType, type CustomLocation } from '../EegWindparkMap/types';
import { IconVariants } from '../Icon/interfaces';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import { BreakpointAspectRatioConfigs } from '../LegacyPicture/image-transformer/types';
import MapExpandToggle from '../MapExpandToggle/MapExpandToggle';
import styles from './EegWindparkMapTooltip.module.scss';
import type { EegWindparkMapTooltipProps } from './types';

const breakpointAspectRatioConfigs: BreakpointAspectRatioConfigs = [
  {
    breakpoint: BreakPoint.XSS,
    width: 375,
    aspectRatio: '16:9',
  },
  {
    breakpoint: BreakPoint.XS,
    width: 576,
    aspectRatio: '16:9',
  },
  {
    breakpoint: BreakPoint.SM,
    width: 720,
    aspectRatio: '16:9',
  },
  {
    breakpoint: BreakPoint.MD,
    width: 450,
    aspectRatio: '16:9',
  },
  {
    breakpoint: BreakPoint.LG,
    width: 450,
    aspectRatio: '16:9',
  },
  {
    breakpoint: BreakPoint.XL,
    width: 450,
    aspectRatio: '16:9',
  },
];

const locationTypeDetails: Record<
  LocationType,
  { label: string; icon: IconVariants }
> = {
  owned: {
    label: 'Eigener Windpark',
    icon: 'user/wind-power',
  },
  managed: {
    label: 'Betriebsgeführter Windpark',
    icon: 'user/wind-power',
  },
  photovoltaic: {
    label: 'Photovoltaik-Park',
    icon: 'user/solar-plant',
  },
  powerStorage: {
    label: 'Stromspeicher',
    icon: 'user/battery',
  },
  office: {
    label: 'Bürostandort',
    icon: 'user/business-building',
  },
};

const badgeDict: Record<CustomLocation['status'], string | null> = {
  planning: 'Projekt in Planung',
  construction: 'Projekt im Bau',
  completed: null,
};

const EegWindparkMapTooltip = ({
  data,
  handleClose,
}: EegWindparkMapTooltipProps) => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useIsMobile(768);
  const handleExpand = () => setExpanded((expanded) => !expanded);
  const transitions = useTransition(data, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.gentle,
  } as const);

  return transitions((props, data) => {
    if (!data) {
      return null;
    }

    const badgeLabel = badgeDict?.[data.status];

    return (
      <animated.div
        className={clsx(styles.base, {
          [styles.expanded]: expanded,
        })}
        style={props}
      >
        {isMobile && (
          <MapExpandToggle handleClick={handleExpand} expanded={expanded} />
        )}
        <div className={styles.close} onClick={handleClose}>
          <Icon
            variant={'action/close'}
            size={isMobile ? 'iconSize18' : 'iconSize24'}
          />
        </div>
        <div
          className={clsx(styles.content, {
            [styles.overlay]: data.type !== 'managed',
          })}
        >
          <div className={styles.type}>
            {badgeLabel && (
              <div className={styles.badge}>
                <Icon variant="user/hourglass" size="iconSize16" />
                {badgeLabel}
              </div>
            )}
            <span className={clsx(styles.label, styles[data.type])}>
              <Icon
                variant={locationTypeDetails[data.type].icon}
                size="iconSize18"
              />
              {locationTypeDetails[data.type].label}
            </span>
          </div>
          {data.type !== 'managed' && <h3>{data.name}</h3>}
          {data.image && (
            <LegacyPicture
              asset={data.image}
              className={styles.image}
              breakpointAspectRatioConfigs={breakpointAspectRatioConfigs}
              fitCover
            />
          )}
          {data.type === 'managed' && (
            <p className={styles.hint}>
              Betriebsführung für einen Kunden-Windpark. Mehr Details dürfen wir
              an dieser Stelle leider nicht zeigen.
            </p>
          )}
          <table className={styles.table} onClick={handleExpand}>
            <tbody>
              {data.details.map((detail: any, index: number) => (
                <tr key={`detail-row-${index + 1}`}>
                  <th>{detail.label}</th>
                  <td>{detail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </animated.div>
    );
  });
};

export default EegWindparkMapTooltip;
