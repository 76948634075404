import { FilterCriteria } from '../EegWindparkMapFilter/types';
import { MarkerType } from './types';

export const ZOOM_DEFAULT = 6;

export const CENTER_DEFAULT = {
  lat: 51.1642292,
  lng: 10.4541194,
};

export const BOOTSTRAP_URL_KEYS = {
  libraries: ['places', 'geometry'],
  language: 'ge',
};

export const INIT_MAP_STATE = {
  mapApiLoaded: false,
  mapInstance: null,
  mapApi: null,
};

export const BOUNDS_GERMANY = {
  sw: { lat: 47.3024876979, lng: 5.98865807458 },
  ne: { lat: 54.983104153, lng: 15.0169958839 },
};

export const OPTIONS = {
  fullscreenControl: false,
  gestureHandling: 'cooperative',
  mapTypeControl: true,
  mapTypeControlOptions: { position: 3 },
  zoomControlOptions: { position: 7 },
  scrollwheel: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

export const LOCATION_TYPES = [
  'owned',
  'managed',
  'photovoltaic',
  'powerStorage',
  'office',
] as const;

export const FILTER_CRITERIA: FilterCriteria[] = [
  {
    type: 'owned',
    label: 'Eigene Windparks',
  },
  {
    type: 'managed',
    label: 'Betriebsgeführte Windparks',
  },
  {
    type: 'photovoltaic',
    label: 'Photovoltaik-Parks',
  },
  {
    type: 'powerStorage',
    label: 'Stromspeicher',
  },
  {
    type: 'office',
    label: 'Bürostandorte',
  },
];

export const MARKER_TYPES: MarkerType = {
  owned: { color: 'gradient', icon: 'user/wind-power' },
  managed: { color: 'blue', icon: 'user/wind-power' },
  photovoltaic: { color: 'yellow', icon: 'user/solar-plant' },
  office: { color: 'turquoise', icon: 'user/business-building' },
  powerStorage: { color: 'violet', icon: 'user/battery' },
};
