import type { Asset } from '@uikit/types';

type Status = 'planning' | 'construction' | 'completed';

export type PublicWindparkInfoNode = {
  area?: string;
  hubHeight?: string;
  id: string;
  image?: Asset;
  installation?: string;
  lat: string;
  lng: string;
  locations?: string;
  name: string;
  path: string;
  power?: string;
  rotorDiameter?: string;
  systemNumber?: string;
  systemType?: string;
  totalOutput?: string;
  type: 'owned' | 'managed' | 'photovoltaic' | 'office' | 'powerStorage';
  status: Status;
  phone?: string;
  address?: string;
};

type PublicWindparkDetail = {
  label: string;
  value: string;
};

const dictionary = {
  locations: 'Standorte',
  systemNumber: 'Anlagenzahl',
  systemType: 'Anlagentyp',
  totalOutput: 'Gesamtleistung (MW)',
  hubHeight: 'Nabenhöhe (m)',
  rotorDiameter: 'Rotordurchmesser (m)',
  installation: 'Inbetriebnahme',
  area: 'Fläche (ha)',
  power: 'Leistung (MWp)',
  address: 'Anschrift',
  phone: 'Telefon',
};

const detailKeys = Object.keys(dictionary) as (keyof typeof dictionary)[];

export function transformEegWindpark(props: PublicWindparkInfoNode) {
  return {
    id: props.id,
    name: props.name,
    type: props.type,
    lat: parseFloat(props.lat),
    lng: parseFloat(props.lng),
    image: props.image,
    status: props.status || 'completed',
    details: detailKeys?.reduce<PublicWindparkDetail[]>((details, detail) => {
      const value = props[detail];

      if (!value) {
        return details;
      }

      return details.concat([{ label: dictionary[detail], value }]);
    }, []),
  };
}
