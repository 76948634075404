import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import getBaseUrl from '@uikit/helpers/getBaseUrl';
import { useQuery } from 'react-query';
import { EEG_WINDPARKS_DATA_PATH } from './consts';
import {
  transformEegWindpark,
  type PublicWindparkInfoNode,
} from './transformEegWindpark';

const transformWindparks = (data: PublicWindparkInfoNode[] | undefined) => {
  return data
    ?.map((windpark) => transformEegWindpark(windpark))
    .filter(({ lat, lng }) => lat && lng);
};

const getEegWindparks = async () => {
  try {
    const data = await ajax.getJsonCached<PublicWindparkInfoNode[]>(
      new URL(EEG_WINDPARKS_DATA_PATH, getBaseUrl()).toString()
    );

    return transformWindparks(data) ?? [];
  } catch (error) {
    logger.error('getEegWindparks', error);
    return [];
  }
};

export default function useEegWindparks(data?: PublicWindparkInfoNode[]) {
  return useQuery('eegWindparks', getEegWindparks, {
    initialData: transformWindparks(data),
    enabled: !data,
  });
}
